import { amgApi } from "@/service/axios"

class UserCommissionService {
	async getUsers(body) {
		let res = await amgApi.post(
			"/credit-experts-digital/users-commission-status",
			body
		)

		return res.data
	}

	async changeUserCommissionStatus(body) {
		let res = await amgApi.post(
			"/credit-experts-digital/change-user-commission-status",
			body
		)

		return res.data
	}

	async trackingUserCommissionStatus(body) {
		let res = await amgApi.post(
			"/credit-experts-digital/tracking-user-commission-status",
			body
		)

		return res.data
	}
}

export default new UserCommissionService()
