<template>
  <b-modal
    ref="commission-details-cs"
    title="Detail Commission"
    size="lg"
    modal-class="modal-primary "
    @hidden="hideModal"
    title-tag="h3"
    hide-footer
  >
    <b-tabs
      pills
      lazy
      nav-class="mb-0 mt-2"
      active-nav-item-class="bg-primary box-shadow-info"
    >
      <b-tab
        v-for="tab in tabs"
        :key="tab.id"
        :title="tab.name"
        @click="changeTab(tab.id)"
      >
      </b-tab>
    </b-tabs>
    <b-card>
      <div class="w-100 mb-1">
        <b-row class="justify-content-end">
          <b-col cols="3">
            <b-form-group label="Month" label-for="month">
              <b-form-select
                id="month"
                v-model="month"
                :options="optionMonths"
                v-if="hasPermission"
                @change="updateTable"
              />
            </b-form-group>
          </b-col>
          <b-col cols="2">
            <b-form-group label="Year" label-for="year">
              <b-form-select
                id="year"
                v-model="year"
                :options="optionYears"
                v-if="hasPermission"
                @change="updateTable"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <b-table
        :key="idTab"
        :fields="fields"
        :items="tableProvider"
        ref="refClientsListCs"
        responsive
        small
        show-empty
        sticky-header="50vh"
      >
        <template #empty>
          <div class="text-center text-primary my-2">
            <strong>No commissions found</strong>
          </div>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(client)="data">
          <span>{{ data.value }}</span
          ><br />
          <small class="text-warning">{{ data.item.account }}</small>
        </template>
        <template #cell(created_at)="data">
          <span>{{ data.value | myGlobalWithHour }}</span>
        </template>
        <template #cell(amount)="data">
          <span>$ {{ Number(data.value).toFixed(2) }}</span>
        </template>
      </b-table>
    </b-card>
  </b-modal>
</template>

<script>
import moment from "moment";
import CommissionsService from "@/views/commons/components/commissions/services/commissions.service";
import { mapGetters } from "vuex";
export default {
  props: {
    agent: {
      type: Object,
      default: () => ({}),
    },
    detail: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      isBusy: false,
      idTab: 1,
      tabs: [
        { id: 1, name: "Actions" },
        { id: 2, name: "Charges" },
        { id: 3, name: "Add/Change Service" },
      ],
      fields: [
        {
          key: "client",
          label: "Client",
        },
        {
          key: "type",
          label: "Type",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "created_at",
          label: "Date",
        },
      ],
      optionMonths: [
        { value: "01", text: "January" },
        { value: "02", text: "February" },
        { value: "03", text: "March" },
        { value: "04", text: "April" },
        { value: "05", text: "May" },
        { value: "06", text: "June" },
        { value: "07", text: "July" },
        { value: "08", text: "August" },
        { value: "09", text: "September" },
        { value: "10", text: "October" },
        { value: "11", text: "November" },
        { value: "12", text: "December" },
      ],
      optionYears: [],
      month: moment().format("MM"),
      year: moment().format("YYYY"),
    };
  },
  mounted() {
    this.toggleModal("commission-details-cs");
    this.fillYears();
  },
  computed: {
    ...mapGetters({ currentUser: "auth/currentUser", skin: "appConfig/skin" }),
    hasPermission() {
      return (
        this.isCeo ||
        this.isChief ||
        this.isSupervisor ||
        this.isAssistantSupervisor
      );
    },
  },
  methods: {
    fillYears() {
      let year = moment().format("YYYY");
      let years = [];
      for (let i = 0; i < 5; i++) {
        years.push({ value: year - i, text: year - i });
      }
      this.optionYears = years;
    },
    updateTable() {
      this.$refs.refClientsListCs.refresh();
    },
    changeTab(id) {
      this.idTab = id;
    },
    async tableProvider() {
      if (this.idTab == 3) {
        let body = {
          user: this.agent.id,
          role: this.agent.role_id,
          module: 6,
          year: this.year,
          month: this.month,
        };
        let res = await CommissionsService.searchCommissionsUserDepartment(
          body
        );
        res = res.filter((item) => Number(item.commission) > 0);

        return res.map((item) => ({
          account: item.account,
          amount: Number(item.commission),
          client: item.lead,
          created_at: item.created_at,
          type: "add/change",
        }));
      }
      let body = {
        agent_id: this.agent.id,
        base_date: moment(this.year + "-" + this.month).format("YYYY-MM-DD"),
        type: this.idTab,
        module_id: this.$route.matched[0].meta.module,
      };
      let res = await CommissionsService.getAgentCommissionList(body);

      return res || [];
    },
    hideModal() {
      this.$emit("hideModal");
    },
  },
};
</script>

<style></style>
