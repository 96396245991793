<template>
  <div>
    <header-slot />
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['table'].refresh()"
    >
      <template #table>
        <b-table
          class="position-relative"
          sticky-header="50vh"
          ref="table"
          primary-key="id"
          responsive="sm"
          no-border-collapse
          show-empty
          :fields="fields"
          :items="tableProvider"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(status)="data">
            <b-badge
              variant="secondary"
              class="badge-glow"
              v-if="data.value === 0"
            >
              DISABLED
            </b-badge>
            <b-badge variant="success" class="badge-glow" v-else>
              ENABLED
            </b-badge>
          </template>
          <template #cell(last_update)="data">
            {{ data.value | myGlobalDay }}
          </template>
          <template #cell(_actions)="data">
            <b-button
              variant="outline"
              class="btn-icon rounded-circle"
              size="sm"
              v-b-tooltip.hover="'Change Permission'"
              @click="changeUserStatus(data.item.user_id, data.item.status)"
            >
              <feather-icon icon="EditIcon" class="text-warning" />
            </b-button>
            <b-button
              variant="outline"
              class="btn-icon rounded-circle"
              size="sm"
              v-b-tooltip.hover="'View Tracking'"
              @click="showTracking(data.item)"
            >
              <feather-icon icon="BookOpenIcon" class="text-primary" />
            </b-button>
            <b-button
              variant="outline"
              class="btn-icon rounded-circle"
              size="sm"
              v-b-tooltip.hover="'Detail Commission'"
              @click="showCommission(data.item)"
            >
              <feather-icon icon="EyeIcon" class="text-success" />
            </b-button>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <b-modal
      :title="currentTrackingUser.name + ' Commission Tracking'"
      v-model="modalTracking"
    >
      <b-table
        :items="trackingProvider"
        ref="ctable"
        sticky-header="22rem"
        style="height: 22rem"
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(new_status)="data">
          <b-badge
            variant="secondary"
            class="badge-glow"
            v-if="data.value === 0"
          >
            DISABLED
          </b-badge>
          <b-badge variant="success" class="badge-glow" v-else>
            ENABLED
          </b-badge>
        </template>
        <template #cell(modified_at)="data">
          {{ data.value | myGlobalWithHour }}
        </template>
      </b-table>
    </b-modal>
    <detail-commission
      v-if="OpenModalDetail"
      @hideModal="hideModal"
      :agent="agent"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import UserCommissionService from "./user-commission.service";
import DetailCommission from "@/views/ce-digital/sub-modules/customer-service/views/user-commission/DetailCommission";
export default {
  components: {
    DetailCommission,
  },
  data() {
    return {
      filters: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search agent or technical assistant...",
        model: "",
      },
      totalRows: 0,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      fields: [
        {
          key: "user_name",
          label: "Agent Name",
        },
        {
          key: "role_name",
          label: "Role",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "last_update",
          label: "Updated At",
        },
        {
          key: "_actions",
          label: "Actions",
          class: "text-center",
        },
      ],
      modalTracking: false,
      currentTrackingUser: {
        id: null,
        name: null,
      },
      OpenModalDetail: false,
      agent: {
        id: null,
        role_id: null,
      },
    };
  },
  computed: { ...mapGetters({ currentUser: "auth/currentUser" }) },
  methods: {
    async tableProvider() {
      let body = {
        per_page: this.paginate.perPage,
        n_page: this.paginate.currentPage,
        user_name: this.filterPrincipal.model,
      };
      let data = await UserCommissionService.getUsers(body);
      this.totalRows = data.total;
      this.startPage = data.from;
      this.toPage = data.to;
      return data.data;
    },
    async changeUserStatus(id, old_status) {
      let body = {
        user_id: id,
        updater: this.currentUser.user_id,
        new_status: false,
      };
      if (old_status === 0) {
        body.new_status = true;
      }
      const { isConfirmed } = await this.showConfirmSwal();
      if (isConfirmed) {
        await UserCommissionService.changeUserCommissionStatus(body);
      }
      this.$refs["table"].refresh();
    },
    showTracking(item) {
      this.currentTrackingUser = {
        id: item.user_id,
        name: item.user_name,
      };
      this.modalTracking = true;
    },
    showCommission(item) {
      this.agent.id = item.user_id;
      this.agent.role_id = item.role_id;
      this.OpenModalDetail = true;
    },
    hideModal() {
      this.OpenModalDetail = false;
    },
    async trackingProvider() {
      let body = {
        user_id: this.currentTrackingUser.id,
      };
      let data = await UserCommissionService.trackingUserCommissionStatus(body);
      return data;
    },
  },
};
</script>
